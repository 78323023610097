<template>
    <div class="page">
        <!-- <div class="page_prev" v-if="page != 1">上一页</div>
        <div :class="page == i?'active':''" v-for="i in Math.ceil(total/limit)" :key="i">{{i}}</div>
        <div class="page_omit">···</div>
        <div class="page_next">下一页</div>
        <div class="page_jump">
            前往
            <input type="text">
            页
        </div> -->
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="limit"
            layout="prev, pager, next, jumper"
            :total="total"
            prev-text="上一页"
            next-text="下一页">
        </el-pagination>
    </div>
</template>

<script>
export default {
    name:'page',
    props:['page','limit','total'],
    data(){
        return{
            currentPage:1
        }
    },
    methods:{
        handleCurrentChange(val) {
            this.currentPage = val
            this.$parent.page = val;
            this.$parent.listFun()
            document.body.scrollTop = 0;
            // firefox
            document.documentElement.scrollTop = 0;
            // safari
            window.pageYOffset = 0;
        }
    }
}
</script>

<style scoped>
.page{
    display: flex;
    align-items: center;
    justify-content: center;
}
.page >>> .el-pager .number{
    padding: 0 16px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #FFFFFF;
    margin: 0 10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 12px;
    color: #333;
    transition: 0.3s;
}
.page >>> .el-pager .number.active{
    background: #5957FF;
    color: #FFFFFF;
}
.page >>> .el-pager .number:hover{
    /* background: #5957FF; */
    color: #5957FF;
}
.page >>> .el-pagination button:disabled{
    display: none;
}
.page >>> .el-pagination button, .page >>> .el-pagination span:not([class*=suffix]){
    height: 40px;
    line-height: 40px;
    padding: 0;
    font-size: 12px;
}
.page >>> .el-pagination button{
    padding: 0 16px;
    border-radius: 10px;
}
.page >>> .el-pagination button:hover{
    background: #5957FF;
    color: #FFFFFF;
}
.page >>> .el-pagination .el-input__inner{
    width: 60px;
    border: none;
    height: 40px;
    border-radius: 10px;
    background: #FFFFFF;
    border-radius: 10px;
}
.page >>> .el-pagination__editor.el-input{
    width: 60px;
}
.page >>> .el-pagination__editor{
    margin: 0 6px;
}
.page >>> .el-pager li.btn-quicknext,.page >>> .el-pager li.btn-quickprev{
    background: transparent;
    line-height: 40px;
}
.page >>> .el-pager li.btn-quicknext:hover,.page >>> .el-pager li.btn-quickprev:hover{
    cursor: default;
    color: #5957FF;
}
/* .page>div.page_omit,.page>div.page_jump{
    background: transparent;
    cursor: default;
}
.page>div.page_omit:hover,.page>div.page_jump:hover{
    background: transparent;
    color: #333;
}
.page>div.page_jump{
    display: flex;
    align-items: center;
}
.page>div.page_jump input{
    display: block;
    width: 60px;
    height: 40px;
    border-radius: 10px;
    background: #FFFFFF;
    margin: 0 5px 0 9px;
    padding: 0;
} */
</style>